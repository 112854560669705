import FiveGridLine_ShadowBottomLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/ShadowBottomLine/ShadowBottomLine.skin';


const FiveGridLine_ShadowBottomLine = {
  component: FiveGridLine_ShadowBottomLineComponent
};


export const components = {
  ['FiveGridLine_ShadowBottomLine']: FiveGridLine_ShadowBottomLine
};


// temporary export
export const version = "1.0.0"
